<template>
  <div class="link">
    <div class="mall">
      <div class="mallName">
        <p>
          {{ detail.merchantName }}
          <span></span>
        </p>
      </div>
      <div class="productName">
        <p>{{ detail.title }}</p>
      </div>
      <div class="productDetail">
        <div class="left">
          <img :src="detail.img" alt="" />
        </div>
        <div class="right">
          <div class="price1">
            <p>
              原价：<span>¥ {{ detail.price / 100 }}元</span>
            </p>
            <p>
              优惠券：<span>¥ {{ detail.coupon/100 }}元</span>
            </p>
          </div>
          <div class="price2">
            <p>
              券后价：<span>¥ {{ detail.afterCouponPrice / 100 }}元</span>
            </p>
          </div>
          <div class="price3" v-if="shopMsg.rebate == 1">
            <p>
              返现比例：<span>{{ detail.commissionRate }}%</span>
            </p>
            <p>
              预估返现：<span>¥ {{ detail.commission / 100 }}元</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <p class="tip">
      注：预估返现根据实付金额与返现比例计算得出，最终结算返现金额可能略有不同，且订单取消、退款、售后也会影响返现金额
    </p>
    <div class="share">
      <div class="title">
        <div class="left">
          <p>
            分享方式
            <span></span>
          </p>
        </div>
        <div class="right" @click="extension">
          <img src="../../assets/img-icon.png" alt="" />
          <p>生成推广图片</p>
        </div>
      </div>
      <div class="linkView">
        <p>{{ detail.cpsUrl }}</p>
      </div>
      <div class="btns">
        <p
          class="copy-btn"
          @click="copy"
          :data-clipboard-text="`${detail.cpsUrl}`"
        >
          复制链接
        </p>
        <p @click="open">打开链接</p>
      </div>
      <div class="linkView">
        <p>{{ detail.kouling }}</p>
      </div>
      <div class="btns">
        <p
          @click="copy"
          class="copy-btn"
          :data-clipboard-text="`${detail.kouling}`"
        >
          复制口令
        </p>
      </div>
    </div>
    <!-- <div class="url">
      <p>{{ detail.url }}</p>
    </div>
    <div class="button">
      <van-button
        type="primary"
        class="copy-btn"
        round
        @click="copy"
        :data-clipboard-text="`${detail.url}`"
        >复制链接</van-button
      >
      <van-button type="primary" style="margin-left: 20px" round @click="open"
        >打开链接</van-button
      >
    </div>
    <div class="url">
      <p>{{ detail.kouling }}</p>
    </div>
    <div class="button">
      <van-button
        class="copy-btn"
        type="primary"
        round
        @click="copy"
        :data-clipboard-text="`${detail.kouling}`"
        >复制口令</van-button
      >
    </div> -->
    <customerService />
  </div>
</template>

<script>
import customerService from "../../component/customerService";
import { getTrans, getShopName } from "../../utils/api";
import Clipboard from "clipboard";
export default {
  data() {
    return {
      detail: {},
      shopId: "",
      shopMsg: {},
    };
  },
  components: { customerService },
  created() {},
  methods: {
    // 生成推广
    extension() {
      this.$toast("暂未开放");
    },
    //获取店铺信息
    async getshopName() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      await getShopName().then((res) => {
        this.shopMsg = res;
        this.shopId = res.id;
        // this.$toast.clear();
      });
    },
    //打开链接
    open() {
      window.location.href = this.detail.cpsUrl;
    },
    //复制链接与口令
    copy() {
      let clipboard = new Clipboard(".copy-btn");
      clipboard.on("success", () => {
        this.$toast.success("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        this.$toast.fail("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    //获取详情
    async getTrans() {
      // this.$toast.loading({
      //   message: "加载中...",
      //   forbidClick: true,
      //   duration: 0,
      // });
      let transId = this.$route.query.transId;
      let params = {
        shopId: this.shopId,
      };
      await getTrans(transId, params)
        .then((res) => {
          this.detail = res.data;
          this.$toast.clear();
        })
        .catch(() => {
          this.$toast.clear();
        });
    },
  },
  async mounted() {
    await this.getshopName();
    await this.getTrans();
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #ff804d;
// /deep/ .van-button--primary {
//   background-color: #FF804D;
//   border: 1px solid #FF804D;
// }
.link {
  padding: 15px;
  .mall {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 10px 15px 20px;
    .mallName {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      p {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 16px;
        padding: 0 5px;
        display: inline-block;
        margin-top: 10px;
        position: relative;
        z-index: 20;
      }
      span {
        width: 100%;
        height: 6px;
        display: inline-block;
        background: $primary-color;
        border-radius: 10px;
        position: absolute;
        z-index: -1;
        bottom: -3px;
        left: 0;
      }
    }
    .productName {
      p {
        font-size: 12px;
        font-weight: bold;
        color: #333333;
        line-height: 17px;
        text-align: justify;
        margin-top: 20px;
      }
    }
    .productDetail {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      .left {
        width: 80px;
        height: 80px;
        // background: cornflowerblue;
        img {
          width: 100%;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-left: 15px;
          p {
            font-size: 10px;
            font-weight: 400;
            color: #333333;
            line-height: 12px;
          }
        }
        .price2 {
          padding: 20px 0;
          span {
            color: #e62828;
            font-weight: bold;
          }
        }
        .price3 {
          span {
            color: #e62828;
            font-weight: bold;
          }
        }
        .price1 {
          span {
            color: #666;
          }
        }
      }
    }
  }
  .tip {
    font-size: 10px;
    font-weight: 400;
    color: #666666;
    line-height: 15px;
    margin: 10px 0;
    text-align: justify;
  }
  .share {
    background: #ffffff;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 10px 15px 20px;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
    }
    .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      p {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 16px;
        padding: 0 5px;
        display: inline-block;
        position: relative;
        z-index: 20;
      }
      span {
        width: 100%;
        height: 6px;
        display: inline-block;
        background: $primary-color;
        border-radius: 10px;
        position: absolute;
        z-index: -1;
        bottom: -3px;
        left: 0;
      }
    }
    .right {
      display: flex;
      align-items: center;
      img {
        width: 16px;
      }
      p {
        font-size: 12px;
        font-weight: bold;
        color: #008fff;
        line-height: 17px;
        margin-left: 5px;
      }
    }
    .linkView {
      padding: 10px 15px;
      background: #ffffff;
      border-radius: 10px;
      border: 1px solid #eeeeee;
      margin-top: 20px;
      p {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        word-break: break-all;
        white-space: normal;
        text-align: justify;
      }
    }
    .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
      p {
        width: 150px;
        height: 30px;
        background: $primary-color;
        border-radius: 15px;
        font-size: 12px;
        font-weight: bold;
        color: #ffffff;
        line-height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
// .url {
//   width: 100%;
//   background: #f2f2f2;
//   border-radius: 4px;
//   padding: 10px;
//   p {
//     font-size: 16px;
//     line-height: 20px;
//     color: #333;
//     word-break: break-all;
//     white-space: normal;
//     text-align: justify;
//   }
// }
// .button {
//   display: flex;
//   margin-top: 10px;
//   margin-bottom: 50px;
// }
</style>